import './App.css';
import Home from './components/Home';

function App() {
  	return (
    	<div className="">
      		<Home />
    	</div>
  	);
}

export default App;
